exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-consulting-tsx": () => import("./../../../src/pages/consulting.tsx" /* webpackChunkName: "component---src-pages-consulting-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-drillholeoptimizer-tsx": () => import("./../../../src/pages/drillholeoptimizer.tsx" /* webpackChunkName: "component---src-pages-drillholeoptimizer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rmsp-tsx": () => import("./../../../src/pages/rmsp.tsx" /* webpackChunkName: "component---src-pages-rmsp-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-training-tsx": () => import("./../../../src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-disclosure-probabilistic-resources-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/disclosure-probabilistic-resources.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-disclosure-probabilistic-resources-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-drillhole-spacing-mineral-classification-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/drillhole-spacing-mineral-classification.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-drillhole-spacing-mineral-classification-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-gaussian-distribution-in-geostatistics-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/gaussian-distribution-in-geostatistics.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-gaussian-distribution-in-geostatistics-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-geostatistics-with-nonlinear-variables-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/geostatistics-with-nonlinear-variables.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-geostatistics-with-nonlinear-variables-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-optimal-composite-length-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/optimal-composite-length.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-optimal-composite-length-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-place-of-resource-modeling-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/place-of-resource-modeling.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-place-of-resource-modeling-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-probabilistic-modeling-of-underground-resources-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/probabilistic-modeling-of-underground-resources.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-probabilistic-modeling-of-underground-resources-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-probabilistic-resource-estimation-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/probabilistic-resource-estimation.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-probabilistic-resource-estimation-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-resource-calculation-with-incomplete-information-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/resource-calculation-with-incomplete-information.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-resource-calculation-with-incomplete-information-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-resource-classification-challenges-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/resource-classification-challenges.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-resource-classification-challenges-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-scenarios-vs-parameter-uncertainty-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/scenarios-vs-parameter-uncertainty.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-scenarios-vs-parameter-uncertainty-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-statement-on-geomet-article-2022-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/statement-on-geomet-article-2022.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-statement-on-geomet-article-2022-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-the-importance-of-training-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/the-importance-of-training.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-the-importance-of-training-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-to-impute-or-not-to-impute-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/to-impute-or-not-to-impute.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-to-impute-or-not-to-impute-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-trends-in-geostatistics-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Blog/trends-in-geostatistics.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-markdown-blog-trends-in-geostatistics-mdx" */),
  "component---src-templates-position-template-tsx-content-file-path-markdown-jobs-principalgeostat-mdx": () => import("./../../../src/templates/positionTemplate.tsx?__contentFilePath=/home/runner/work/rmshome/rmshome/markdown/Jobs/principalgeostat.mdx" /* webpackChunkName: "component---src-templates-position-template-tsx-content-file-path-markdown-jobs-principalgeostat-mdx" */)
}

